@use 'src/styles/colors';
@use 'src/styles/vars';

.cart-summary-ticket-info {
  font-weight: 400;
  display: list-item;
}

.berg-pages-cart-table {
  margin: 10px 0 20px;
  max-width: 1120px;
}

.cart-summary-footer {
  &__img {
    align-self: end;
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      align-self: center;
    }
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .cart-summary-footer:first-child {
    align-self: center;

    & button {
      align-self: center;
    }
  }

  .cart-summary-footer:last-child {
    align-self: center;
  }

  .cart-summary-footer__summary {
    align-self: center;
  }

  .cart-summary-footer__button {
    align-self: center;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-stack.berg-stack--column.cart-summary-footer:first-child {
    gap: 1rem !important;
  }
}

.cart-summary-grid {
  display: grid;
  gap: 20px;
  margin-top: 15px;
  grid-template-rows: auto auto;
  grid-template-areas:
    'checkbox summary'
    'go-back button';

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-areas:
      'checkbox'
      'summary'
      'button'
      'go-back';
  }

  &__checkbox {
    grid-area: checkbox;
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: grid;
      place-content: center;
    }

    &--link {
      color: colors.$primary-dark;
      text-decoration: none;
    }

    .berg-checkbox {
      align-items: center;
      width: 110%;

      @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
        width: 100%;
      }

      label {
        font-size: 14px;
        padding-right: 0;
        font-weight: 600;
      }
    }
  }

  &__summary {
    grid-area: summary;

    align-self: end;

    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: grid;
      place-self: center;
    }
  }

  &__go-back {
    grid-area: go-back;

    justify-self: start;
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: center;
    }
  }

  &__button {
    grid-area: button;

    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;

    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      align-items: center;
    }
  }
}
