@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-button {
  font-weight: 500;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  font-size: 18px;
  vertical-align: middle;
  line-height: 36px;
  padding: 6px 24px;
  height: 43px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  // color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    line-height: 20px;
    height: 54px;
    width: 85vw;
  }

  &__primary {
    background-color: colors.$polana-primary;
    color: colors.$white;

    &:hover {
      background-color: colors.$primary-dark;
    }
  }

  &__primary-dark {
    background-color: colors.$primary-dark;
    color: colors.$white;

    &:hover {
      background-color: colors.$primary-light;
    }
  }

  &__secondary {
    background-color: colors.$white;
    color: colors.$primary-light;
    border: 2px solid colors.$primary-light;

    &:hover {
      color: colors.$primary-dark;
      border: 2px solid colors.$primary-dark;
    }
  }

  &__red {
    background-color: colors.$polana-red;
    color: colors.$white;

    &:hover {
      background-color: colors.$polana-dark-red;
    }
  }

  &__gray-400 {
    background-color: colors.$gray-400;
    color: colors.$white;

    &:hover {
      background-color: colors.$gray-600;
    }
  }

  &__white {
    background-color: colors.$white;
    color: colors.$primary-dark;
  }

  &__white-border {
    background-color: colors.$white;
    color: colors.$primary-dark;
    border: 1px solid colors.$primary-dark;
  }
}
